<template>
  <div class="study">
    <div
      class="study__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="study__title">
        <h3>{{ title }}</h3>
        <p>{{ sub }}</p>
      </div>
      <Slider :data="dataSlider" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Slider = () => import('../Components/Slider.vue');

export default {
  name: 'Study',
  data() {
    return {
      length: 3,
      title: this.$t('people').studyDevelop.title,
      sub: this.$t('people').studyDevelop.sub,
      studyDevelop: this.$t('people').studyDevelop.data,
      dataSlider: [
        {
          icon: '/images/icons/people/japan.svg',
          background: '/images/people/japan-course.jpg',
          step: this.$t('people').studyDevelop.data[0].step,
          sub: this.$t('people').studyDevelop.data[0].sub
        },
        {
          icon: '/images/icons/people/english.svg',
          background: '/images/people/english-course.png',
          step: this.$t('people').studyDevelop.data[1].step,
          sub: this.$t('people').studyDevelop.data[1].sub
        },
        {
          icon: '/images/icons/people/gotoJanpan.svg',
          background: '/images/people/goto-japan.jpg',
          step: this.$t('people').studyDevelop.data[2].step,
          sub: this.$t('people').studyDevelop.data[2].sub
        },
        {
          icon: '/images/icons/people/micro.svg',
          background: '/images/people/seminar.jpg',
          step: this.$t('people').studyDevelop.data[3].step,
          sub: this.$t('people').studyDevelop.data[3].sub
        },
        {
          icon: '/images/icons/people/course.svg',
          background: '/images/people/internal-culture.png',
          step: this.$t('people').studyDevelop.data[4].step,
          sub: this.$t('people').studyDevelop.data[4].sub
        }
      ],
      window: 0
    };
  },
  mounted() {},
  watch: {
    lang() {
      this.title = this.$t('people').studyDevelop.title;
      this.sub = this.$t('people').studyDevelop.sub;
      this.dataSlider = [
        {
          icon: '/images/icons/people/japan.svg',
          background: '/images/people/japan-course.jpg',
          step: this.$t('people').studyDevelop.data[0].step,
          sub: this.$t('people').studyDevelop.data[0].sub
        },
        {
          icon: '/images/icons/people/english.svg',
          background: '/images/people/english-course.png',
          step: this.$t('people').studyDevelop.data[1].step,
          sub: this.$t('people').studyDevelop.data[1].sub
        },
        {
          icon: '/images/icons/people/gotoJanpan.svg',
          background: '/images/people/goto-japan.jpg',
          step: this.$t('people').studyDevelop.data[2].step,
          sub: this.$t('people').studyDevelop.data[2].sub
        },
        {
          icon: '/images/icons/people/micro.svg',
          background: '/images/people/seminar.jpg',
          step: this.$t('people').studyDevelop.data[3].step,
          sub: this.$t('people').studyDevelop.data[3].sub
        },
        {
          icon: '/images/icons/people/course.svg',
          background: '/images/people/internal-culture.png',
          step: this.$t('people').studyDevelop.data[4].step,
          sub: this.$t('people').studyDevelop.data[4].sub
        }
      ];
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  props: {
    data: []
  },
  components: { Slider }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}

.study {
  position: relative;
  width: 100%;
  padding: 50px 0;
  background-image: url('/images/bg/study.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  .study__container {
    margin: 0 auto;
  }
  .study__title {
    text-align: center;
    z-index: 0;
    margin: 20px auto;
    margin-bottom: 52px;
    max-width: 752px;
    h3 {
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      margin-bottom: 16px;
    }
    & > p {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
    }
  }
  .study__slider {
    display: flex;
    gap: 4%;
  }
  .study__control {
    display: flex;
    flex-direction: column;
  }
  .study___control__item {
    position: relative;
    padding: 16px 24px;
    border-radius: 10px;
    width: 460px;
    gap: 16px;
  }
  .study___control__item--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: initial;
    text-align: left;
    h3 {
      flex: 1;
      font-weight: 700;
      font-size: 21px;
      color: var(--brown-text);
      text-transform: none;
      font-weight: 400;
      margin: 0;
    }
    .study___control__item--img {
      width: 50px !important;
      height: 50px;
      margin-left: 8px;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .study__control__item--add {
    display: none;
  }
  .study__block {
    position: relative;
    padding: 0;
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      top: -8%;
      right: 30%;
      background-color: var(--orange-background);
      width: 136px;
      height: 136px;
      border-radius: 50%;
      z-index: -1;
    }
  }
  .study__block__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    h4 {
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      margin-top: 30px;
      white-space: pre-line;
      color: var(--grey-text);
    }
    & > img {
      width: 80%;
    }
  }
}

@media (max-width: 1024px) {
  .study .study___control__item {
    width: 350px;
  }
  .study .study__title {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .study .study__title {
    h3 {
      font-size: 32px;
    }
  }
  .study__slider {
    justify-content: center;
  }
  .study__block {
    display: none;
  }
  .study .study___control__item {
    width: none;
  }
}
</style>
